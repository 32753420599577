<template>
  <v-card class="mb-5">
    <v-card-text class="py-0 px-0 px-md-4 py-md-4">
      <v-data-table
            ref="tabelaCompras"
            dense
            show-expand
            single-expand
            item-key="row-key"
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            :expanded.sync="expanded"
            :headers="headers"
            :items="data"
            :loading="loading"
            :no-data-text="noData"
            :items-per-page="$dataTableConfig.getItemsPerPageCustom()"
            :footer-props="$dataTableConfig.getFooterProps()"
            :loading-text="$dataTableConfig.getLoadText()"
            :mobile-breakpoint="0"
          >
            <template v-slot:[`body.append`]="{ items }">
              <tr>
                <th v-for="header in headers" :class="header.class" :style="{ 'text-align': header.align !== 'right'? 'left' : 'right' }">
                  <span v-if="header.total === 'title'">Total</span>
                  <span v-else-if="header.total === 'sum'" >{{ typeof header.totalFormat === 'function'? header.totalFormat(data.reduce((sum, item) => item[header.value] + sum, 0)): data.reduce((sum, item) => item[header.value] + sum, 0) }}</span>
                  <span v-else-if="header.total === 'count'">{{ typeof header.totalFormat === 'function'? header.totalFormat(data.length): data.length }}</span>
                  <span v-else>&nbsp;</span>
                </th>
              </tr>
            </template>
            <template v-slot:progress>
              <v-progress-linear
                absolute
                indeterminate
                color="green"
              />
            </template>

          <template v-slot:[`item.TipoOcSc`]="{ item }">
            <v-chip
                class="font-weight-medium"
                :color="getTypeByTns(item.codtns).color"
                dark
                label
                small
            >
              {{ getTypeByTns(item.codtns).text }}
            </v-chip>
          </template>
            <template v-slot:[`item.Etapa`]="{ item }">
              <v-chip
                  class="font-weight-medium"
                  :color="getColor(item)"
                  dark
                  label
                  :outlined="[9, 10].includes(parseInt(item.status_code))"
                  small
              >
                {{ item.status }}
              </v-chip>
            </template>

            <template v-slot:[`item.codigo_oc`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ item.codigo_oc || '0' }}</span>
            </template>

            <template v-slot:[`item.codigo_sc`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ item.codigo_sc || '0' }}</span>
            </template>

            <template v-slot:[`item.data_emissao_sc`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ validDate(item.data_emissao_sc) }}</span>
            </template>

            <template v-slot:[`item.data_aprovacao_sc`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ validDate(item.data_aprovacao_sc) }}</span>
            </template>

            <template v-slot:[`item.data_emissao_oc`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ validDate(item.data_emissao_oc) }}</span>
            </template>

            <template v-slot:[`item.data_aprovacao_nivel_1_oc`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ validDate(item.data_aprovacao_nivel_1_oc) }}</span>
            </template>

            <template v-slot:[`item.data_aprovacao_nivel_2_oc`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ validDate(item.data_aprovacao_nivel_2_oc) }}</span>
            </template>

            <template v-slot:[`item.last_datapr`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ validDate(item.last_datapr) }}</span>
            </template>

            <template v-slot:[`item.last_abrnap`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ item.last_abrnap }}</span>
            </template>

            <template v-slot:[`item.next_abrnap`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ item.next_abrnap }}</span>
            </template>

            <template v-slot:[`item.data_compra_oc`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ validDate(item.data_compra_oc) }}</span>
            </template>

            <template v-slot:[`item.data_entrega`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ validDate(item.data_entrega) }}</span>
            </template>

            <template v-slot:[`item.data_vencimento_nf`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ validDate(item.data_vencimento_nf) }}</span>
            </template>

            <template v-slot:[`item.data_cancelada_sc`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ validDate(item.data_cancelada_sc) }}</span>
            </template>

            <template v-slot:[`item.data_emissao_nf`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ validDate(item.data_emissao_nf) }}</span>
            </template>

            <template v-slot:[`item.data_vencimento_aberto_nf`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ validDate(item.data_vencimento_aberto_nf) }}</span>
            </template>

            <template v-slot:[`item.producao`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ item.producao }}</span>
            </template>

            <template v-slot:[`item.fornecedor_id`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ item.fornecedor_id }}</span>
            </template>

            <template v-slot:[`item.solicitante`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ item.solicitante }}</span>
            </template>

            <template v-slot:[`item.fornecedor`]="{ item }">
              <span :style="item.status_code === 2 ? 'color:red': ''">{{ item.fornecedor }}</span>
            </template>

            <template v-slot:[`item.itens_total`]="{ item }">
              <span class="num_margin" :style="item.status_code === 2 ? 'color:red': ''">{{ item.itens_total }}</span>
            </template>

            <template v-slot:[`item.quantidade_entregas`]="{ item }">
              <span class="num_margin" :style="item.status_code === 2 ? 'color:red': ''">{{ item.quantidade_entregas }}</span>
            </template>

            <template v-slot:[`item.quantidade_parcelas`]="{ item }">
              <span class="num_margin" :style="item.status_code === 2 ? 'color:red': ''">{{ item.quantidade_parcelas }}</span>
            </template>

            <template v-slot:[`item.valor_total`]="{ item }">
              <span class="num_margin" :style="item.Etapa === 'Reprovada' ? 'color:red': ''">{{ $stringFormat.formatNumber(item.valor_total, 2) }}</span>
            </template>

            <template v-slot:[`item.desconto`]="{ item }">
              <span class="num_margin" :style="item.status_code === 2 ? 'color:red': ''">{{ $stringFormat.formatNumber(item.desconto, 2) }}</span>
            </template>

            <template v-slot:[`item.despesas`]="{ item }">
              <span class="num_margin" :style="item.status_code === 2 ? 'color:red': ''">{{ $stringFormat.formatNumber(item.despesas, 2) }}</span>
            </template>

            <template v-slot:[`item.frete`]="{ item }">
              <span class="num_margin" :style="item.status_code === 2 ? 'color:red': ''">{{ $stringFormat.formatNumber(item.frete, 2) }}</span>
            </template>

            <template #item.numero_nf="{item}">
              <div class="num_margin" :style="item.status_code === 2 ? 'color:red': ''">
                {{ item.numero_nf || '-' }}
              </div>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <VisualizaSolicitacaoProdutoComponents :compra="item" @entregue="getData"></VisualizaSolicitacaoProdutoComponents>
              </td>
            </template>
          </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>

import ApiService from '@/core/services/api.service'

import VisualizaSolicitacaoProdutoComponents from './VisualizaSolicitacaoProdutoComponents.vue'

export default {
  components: {
    VisualizaSolicitacaoProdutoComponents,
  },
  props: {
    headers: {
      type: Object,
    },
    filtros: {
      type: Object,
    },
  },
  data: () => ({
    // General
    loading: false,
    noData: 'Nenhuma informação encontrada',

    // Form Data
    data: [],
    // Expand Data Table
    expanded: [],
  }),

  watch: {
    filtros () {
      this.$router.push({ query: this.filtros, silent: true }).catch((e) => e)
      this.getData()
    },
  },

  mounted () {
    this.getData()

    this.$refs.tabelaCompras.$el.querySelector('thead').addEventListener('wheel', (evt) => {
      evt.preventDefault()
      this.$refs.tabelaCompras.$el.querySelector('.v-data-table__wrapper').scrollLeft += evt.deltaY
    })
  },

  methods: {
    getTypeByTns (tns) {
      switch (tns) {
        case '91410':
          return {
            text: 'SCC',
            color: '#F3C200',
          }
        case '91411':
          return {
            text: 'SCE',
            color: '#EF4836',
          }
        case '90410':
          return {
            text: 'OCC',
            color: '#26C281',
          }
        case '90411':
          return {
            text: 'OCE',
            color: '#D91E18',
          }
        case '90412':
        case '90413':
          return {
            text: 'OCR',
            color: '#9A12B3',
          }
        case '90400':
          return {
            text: 'OC',
            color: '#158F77',
          }
        case '90499':
          return {
            text: 'CD',
            color: '#7A328F',
          }
        case '91400':
          return {
            text: 'SC',
            color: '#F3C200',
          }
        default:
          return '-'
      }
    },
    getData () {
      if (this.loading) {
        return
      }

      this.loading = true
      this.expanded = []
      const filtros = {
        data_inicial: this.filtros.dataInicial,
        data_final: this.filtros.dataFinal,
        empresa_id: this.filtros.empresa,
        producao_id: this.filtros.producao,
        unidade_negocio: this.filtros.unidadeNegocio,
        estagio_compra: this.filtros.estagioCompra,
        situacao: this.filtros.situacao,
        possui_cotacao: this.filtros.possuiCotacao,
        codigo_oc: this.filtros.codigoOC,
        codigo_sc: this.filtros.codigoSC,
        produto: this.filtros.produto,
        fornecedor: this.filtros.fornecedor,
        comprador: this.filtros.comprador,
        observacao: this.filtros.observacao,
        nota_fiscal: this.filtros.notaFiscal,
        lancado_nota: this.filtros.lancadoNota,
      }

      ApiService
        .get(
          '/compras/produtos',
          '?' + this.$stringFormat.queryParams(filtros),
        )
        .then((res) => {
          const data = res.data.data.map((item, index) => ({ 'row-key': `${index}`, ...item }))

          const convencional = data.filter((item) => item.codtns === '91410')
          const urgentes = data.filter((item) => item.codtns === '91411')
          const outras = data.filter((item) => !['91410', '91411'].includes(item.codtns))

          // merge urgente, convencional and outras
          this.data = [...urgentes, ...convencional, ...outras]

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    validDate (value) {
      if (value == null) {
        return '-'
      }

      if (value < '1901-01-01') {
        return '-'
      }

      return this.$stringFormat.dateISOToBRL(value)
    },

    getColor (item) {
      if (item.codigo_sc === '0') {
        return 'purple'
      }

      return ([
        '#FDD01C',
        '#3F6883',
        '#E36968',
        '#0090D9',
        '#E36968',
        '#0AA699',
        '#0AA699',
        '#0AA699',
        '#0AA699',
        'grey lighten-1',
        'grey lighten-1',
        '#D1DADE',
        '#1F3853',
      ])[item.status_code]
    },
  },
}
</script>

<style>
.v-btn--height-fix {
  width: 100%;
  height: 40px;
}
.theme--light.v-icon:focus::after {
  opacity: 0 !important;
}
.v-btn--height-fix {
  width: 100%;
  height: 40px;
}
.theme--light.v-icon:focus::after {
  opacity: 0 !important;
}
.label.label-lg.label-inline {
  width: 100%;
}
.custom-switch,
.custom-switch .v-input__slot,
.custom-switch label {
  padding: 0 !important;
  margin: 0 !important;
}
.custom-switch .v-input--selection-controls__ripple {
  display: none !important;
}
</style>
