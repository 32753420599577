<template>
  <v-container>
    <custom-snackbar
        ref="snackbar"
    />
    <v-form ref="form">
      <v-data-table
          item-key="seqipo"
          hide-default-footer
          dense
          :headers="headersItens"
          :items="itens"
          :loading="loadingItens"
          :loading-text="$dataTableConfig.getLoadText()"
          :items-per-page="-1"
          :mobile-breakpoint="0"
      >

        <template v-slot:progress>
          <v-progress-linear
              absolute
              indeterminate
              color="green"
          />
        </template>
        <template v-slot:[`item.lancado_nota`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <span v-if="item.lancado_nota === '3'">P</span>
                    <span v-else-if="item.lancado_nota === '2'">T</span>
                    <span v-else-if="item.lancado_nota === '1'">-</span>
                    <span v-else>Não</span>
                  </span>
            </template>
            <span v-if="item.lancado_nota === '3'">Parcial</span>
            <span v-else-if="item.lancado_nota === '2'">Total</span>
            <span v-else>Não lançada</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.valor_unitario`]="{ item }">
          <span class="num_margin">{{ $stringFormat.formatNumber(item.valor_unitario, 2) }}</span>
        </template>

        <template v-slot:[`item.valor_descontos`]="{ item }">
          <span class="num_margin">{{ $stringFormat.formatNumber(item.valor_descontos, 2) }}</span>
        </template>

        <template v-slot:[`item.valor_outros`]="{ item }">
          <span class="num_margin">{{ $stringFormat.formatNumber(item.valor_outros, 2) }}</span>
        </template>

        <template v-slot:[`item.valor_total`]="{ item }">
          <span class="num_margin">{{ $stringFormat.formatNumber(item.valor_total, 2) }}</span>
        </template>

        <template v-slot:[`item.quantidade_solicitada`]="{ item }">
                        <span class="num_margin">
                          {{ $stringFormat.formatNumber(item.quantidade_solicitada, 2) }}
                          {{ item.unidade_medida }}
                        </span>
        </template>

        <template v-slot:[`item.quantidade_entregue`]="{ item }">
                        <span class="num_margin">
                          {{ $stringFormat.formatNumber(item.quantidade_entregue, 2) }}
                          {{ item.unidade_medida }}
                        </span>
        </template>

        <template v-slot:[`item.quantidade_nota`]="{ item }">
                        <span class="num_margin">
                          {{ $stringFormat.formatNumber(item.quantidade_nota, 2) }}
                          {{ item.unidade_medida }}
                        </span>
        </template>

        <template v-slot:[`item.data_entregue`]="{ item }">
          <span class="num_margin">{{ $stringFormat.dateISOToBRL(item.data_entregue) }}</span>
        </template>

        <template v-slot:[`item.data_vencimento_real`]="{ item }">

          <span class="num_margin">{{ $stringFormat.dateISOToBRL(item.data_vencimento_real) }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div v-if="disabledVisualizacaoEntrega && item.quantidade_entregue > 0">
            <EditaEntregaSolicitacoesProdutosComponent :item="item" @entregue="$emit('entregue')"/>
          </div>
        </template>
      </v-data-table>

      <div class="mb-5 mt-3" v-if="compra.permite_entrega && itens.length > 0">
        <v-btn
            color="info mr-2"
            small
            dark
            @click="validarEntregaTotal"
        >
          Entrega Total
        </v-btn>

        <v-btn
            color="info"
            small
            dark
            @click="validarEntregaParcial"
        >
          Entrega Parcial
        </v-btn>
      </div>
      <div class="mb-5 mt-3" v-if="compra.status_code === 1 && itens.length > 0">
        <v-btn
            color="info mr-2"
            small
            dark
            @click="assumirCotacao"
        >
          Assumir Cotacao
        </v-btn>
      </div>

    </v-form>
    <hr class="my-3"/>

    <h6 v-if="compra.solicitante !== '-' && compra.solicitante !== '' && compra.solicitante !== null">Solicitante: {{ compra.solicitante }}</h6>
    <h6 v-if="compra.cotador !== '-' && compra.cotador !== '' && compra.cotador !== null">Cotador: {{ compra.cotador }}</h6>
    <h6 v-if="compra.comprador !== '-' && compra.comprador !== '' && compra.comprador !== null">Comprador: {{ compra.comprador }}</h6>
    <h6 v-if="compra.fornecedor !== '-' && compra.fornecedor !== '' && compra.fornecedor !== null">Fornecedor: {{ compra.fornecedor }} ({{ compra.fornecedor_id }})</h6>
    <h6 v-if="compra.data_previsao_sc !== '-' && compra.data_previsao_sc !== '' && compra.data_previsao_sc !== null">Data Desejada: {{ $stringFormat.formatDate(compra.data_previsao_sc) }}</h6>
    <h6 v-if="compra.observacao !== '-' && compra.observacao !== ''">{{compra.sc > 0 ? 'Observação': 'Justificativa'}}: {{ compra.observacao }}</h6>
    <h6 v-if="compra.frete !== 0 && compra.frete !== '.00'">Frete: R$ {{ $stringFormat.formatNumber(compra.frete, 2) }}</h6>
    <h6 v-if="compra.despesas !== 0 && compra.despesas !== '.00'">Outras Despesas: R$ {{ $stringFormat.formatNumber(compra.despesas, 2) }}</h6>
    <h6 v-if="compra.desconto !== 0 && compra.desconto !== '.00'">Desconto: R$ {{ $stringFormat.formatNumber(compra.desconto, 2) }}</h6>
    <AssumirCotacaoSolicitacoesProdutosComponent v-model="showAssumirCotacao" :compra="compra" :itens="itens" @entregue="$emit('entregue')"/>
    <EntregaParcialSolicitacoesProdutosComponent v-model="showEntregaParcial" :compra="compra" :itens="itens" @entregue="$emit('entregue')"/>
    <EntregaTotalSolicitacoesProdutosComponent v-model="showEntregaTotal" :compra="compra" @entregue="$emit('entregue')"/>
  </v-container>
</template>

<script>

import ApiService from '@/core/services/api.service'

import EditaEntregaSolicitacoesProdutosComponent from './dialogs/EditaEntregaSolicitacoesProdutosComponent.vue'
import EntregaTotalSolicitacoesProdutosComponent from './dialogs/EntregaTotalSolicitacoesProdutosComponent.vue'
import EntregaParcialSolicitacoesProdutosComponent from './dialogs/EntregaParcialSolicitacoesProdutosComponent.vue'
import AssumirCotacaoSolicitacoesProdutosComponent from './dialogs/AssumirCotacaoSolicitacoesProdutosComponent.vue'

export default {
  components: { AssumirCotacaoSolicitacoesProdutosComponent, EditaEntregaSolicitacoesProdutosComponent, EntregaParcialSolicitacoesProdutosComponent, EntregaTotalSolicitacoesProdutosComponent },
  props: {
    compra: {
      type: Object,
    },
  },
  data: () => ({
    itens: [],
    loadingItens: false,
    showEntregaParcial: false,
    showEntregaTotal: false,
    showAssumirCotacao: false,
  }),

  computed: {
    disabledVisualizacaoEntrega () {
      return ([9, 10]).includes(this.compra.status_code)
    },
    headersItens () {
      return [
        { text: 'Código Produto', value: 'produto_id' },
        { text: 'Produto', value: 'produto' },
        { text: 'Bem Patri.', value: 'bem_patrimonial' },
        { text: 'NF Lançada', value: 'lancado_nota' },
        { text: 'Qtd. Solicitado', value: 'quantidade_solicitada', align: 'right' },
        { text: 'Qtd. Entregue', value: 'quantidade_entregue', align: 'right' },
        { text: 'Qtd. Nota', value: 'quantidade_nota', align: 'right' },
        { text: 'Valor Unitário (R$)', value: 'valor_unitario', align: 'right' },
        { text: 'Valor Descontos (R$)', value: 'valor_descontos', align: 'right' },
        { text: 'Outros Valor (R$)', value: 'valor_outros', align: 'right' },
        { text: 'Valor Total (R$)', value: 'valor_total', align: 'right' },
        { text: 'Numero Nota', value: 'numero_nota', align: 'right' },
        { text: 'Data Entrega', value: 'data_entregue', align: 'right' },
        { text: 'Data VencimentoNF', value: 'data_vencimento_real', align: 'right' },
        { value: 'actions', align: 'right' },
      ]
    },
  },
  mounted () {
    this.init()
  },
  watch: {
    compra () {
      this.init()
    },
  },
  methods: {
    init () {
      this.itens = []
      this.loadingItens = true

      ApiService
        .get('/compras/produtos/' + this.compra.compras_id + '/itens/' + this.compra.cotador_id)
        .then((res) => res.data)
        .then((data) => data.data)
        .then((itens) => {
          this.itens = itens
          this.loadingItens = false
        })
    },
    validarEntregaTotal () {
      if (this.compra.entrega_parcial) {
        this.$refs.snackbar.show('Entrega Total Bloqueada', 'Não é possível realizar uma entrega total para uma ordem de compra com entrega parcial registrada.', 'alert', 5000, false, true)
        return
      }

      this.showEntregaTotal = true
    },
    validarEntregaParcial () {
      if (this.compra.sc === '0') {
        this.$refs.snackbar.show('Entrega Parcial Bloqueada', 'Para compras direta só é possível realizar entrega total.', 'alert', 5000, false, true)
        return
      }

      this.showEntregaParcial = true
    },
    assumirCotacao () {
      this.showAssumirCotacao = true
    },
  },
}
</script>
