<template>
  <div>
    <custom-snackbar
        ref="snackbar"
    />
    <ActionsListagemSolicitacoesProdutosComponent v-model="headers" :filtro="filtro"></ActionsListagemSolicitacoesProdutosComponent>
    <FilterListagemSolicitacoesProdutosComponent v-model="filtro"></FilterListagemSolicitacoesProdutosComponent>
    <ListagemSolicitacoesProdutosComponents :filtros="filtro" :headers="headers"></ListagemSolicitacoesProdutosComponents>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import FilterListagemSolicitacoesProdutosComponent from './components/FilterListagemSolicitacoesProdutosComponent.vue'
import ActionsListagemSolicitacoesProdutosComponent from './components/ActionsListagemSolicitacoesProdutosComponent.vue'
import ListagemSolicitacoesProdutosComponents from './components/ListagemSolicitacoesProdutosComponents.vue'
export default {
  components: { ListagemSolicitacoesProdutosComponents, FilterListagemSolicitacoesProdutosComponent, ActionsListagemSolicitacoesProdutosComponent },
  data: (instance) => {
    return {
      filtro: {
        empresa: instance.$route.query.empresa ? parseInt(instance.$route.query.empresa) : 0,
        producao: instance.$route.query.producao ? parseInt(instance.$route.query.producao) : 0,
        dataInicial: instance.$route.query.dataInicial ? instance.$route.query.dataInicial : instance.$date.getDateNowIso(),
        dataFinal: instance.$route.query.dataFinal ? instance.$route.query.dataFinal : instance.$date.getDateNowIso(),
        unidadeNegocio: instance.$route.query.unidadeNegocio || 0,
        estagioCompra: instance.$route.query.estagioCompra || 2,
        possuiCotacao: instance.$route.query.possuiCotacao || 'T',
        situacao: instance.$route.query.situacao || 'Z',
        codigoOC: instance.$route.query.codigoOC || '',
        codigoSC: instance.$route.query.codigoSC || '',
        produto: instance.$route.query.produto || '',
        fornecedor: instance.$route.query.fornecedor || '',
        comprador: instance.$route.query.comprador || '',
        observacao: instance.$route.query.observacao || '',
        notaFiscal: instance.$route.query.notaFiscal || '',
        lancadoNota: instance.$route.query.lancadoNota || '',
      },
      headers: [],
    }
  },
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Compras' },
      { title: 'Listagem das Solicitações Produtos' },
    ])

    // this.$refs.snackbar.show('teste', 'teste 2', 'success', 30000, true)
  },
}
</script>
